@import "~@mobiscroll/react/dist/css/mobiscroll.scss";

.calendar {
  font-family: "Noto Sans";
  width: calc(100vw - 270px) !important;
  height: calc(100vh - 130px) !important;
  overflow-y: auto;

  .mbsc-timeline-resource-header-cont {
    width: 300px;
    background-color: #7b7b7b !important;
  }

  .mbsc-timeline-header-week,
  .mbsc-timeline-resource-header-cont {
    background-color: #7b7b7b;
  }

  .mbsc-timeline-resources {
    width: 300px;
  }
  .mbsc-timeline-resource {
    position: relative;
    // background-color: #090909 !important;

    .mbsc-timeline-resource-title {
      padding: 0;
    }
    .mbsc-timeline-resource-icon {
      position: absolute;
      z-index: 1;
    }
  }

  // .mbsc-calendar-wrapper {
  //   display: none;
  // }

  // .mbsc-timeline-resource-header-cont {
  //   border-bottom: hidden !important;
  // }

  .mbsc-material-dark.mbsc-timeline-header-active {
    color: white;
  }

  // .mbsc-material-dark {
  //   border-bottom: hidden !important;
  // }

  // .mbsc-timeline-header-week-last.mbsc-ltr {
  //   border-right: none;
  // }

  .mbsc-timeline-header-bg {
    font-family: Roboto;
  }

  .mbsc-material-dark.mbsc-timeline-header-active::after {
    background-color: transparent !important;
  }

  .mbsc-timeline-header-week {
    font-size: 12px;
  }

  .mbsc-schedule-time-indicator-time {
    font-family:
      Noto Sans,
      Roboto,
      helvetica,
      Arial,
      sans-serif;
    margin-left: 0;
  }

  .mbsc-timeline-header-week-text {
    display: flex;
    font-family:
      Noto Sans,
      Roboto,
      helvetica,
      Arial,
      sans-serif;
    flex-direction: column-reverse;
    align-items: center;
    // width: 70px;
  }

  .mbsc-timeline-header-week-text::before {
    content: "|";
    margin-top: -10px;
  }

  .mbsc-timeline-parent:nth-child(even) {
    background: none;
  }

  .mbsc-material.mbsc-timeline-column {
    border-right: hidden !important;
  }

  // .mbsc-timeline-resource.mbsc-ltr {
  //   border-bottom: hidden !important;
  // }

  // .mbsc-timeline-grid {
  //   .mbsc-timeline-row {
  //     border: hidden !important;
  //   }
  // }

  .country-resource,
  .child-resource {
    padding-left: 2px;
  }

  .mbsc-timeline-resource {
    font-size: 14px;
    padding: 0 !important;

    .mbsc-timeline-resource-icon {
      z-index: 2;
      svg {
        position: absolute;
        left: 8px;
      }
    }

    .mbsc-timeline-resource-title {
      padding: 0;
    }
  }

  .mbsc-schedule-time-indicator {
    border: hidden !important;

    &:before {
      content: "";
      position: absolute;
      border-right: 1px dashed #80dfeb;
      top: 0;
      bottom: 0;
      left: 17px;
      right: 0;
    }
  }

  .lease-area-timeline-row {
    .mbsc-timeline-resource-icon {
      svg {
        position: absolute;
        left: 24px;
      }
    }
  }
}
