body {
  margin: 0;
  overflow: hidden;
  font-family: "Noto Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0e9eff;
  border-radius: 4px;
}

/* For Firefox */
/* width */
* {
  scrollbar-width: thin;
  scrollbar-color: #0e9eff #000000;
}

/* For Internet Explorer and Edge */
/* width */
::-ms-scrollbar {
  width: 8px;
}

/* Track */
::-ms-scrollbar-track {
  background: #000000;
}

/* Handle */
::-ms-scrollbar-thumb {
  background: #0e9eff;
  border-radius: 4px;
}

.MuiChip-label {
  width:150px;
}
